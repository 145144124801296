// src/pages/_app.tsx
import "../styles/globals.css";
import type { AppType } from "next/app";
import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { configureChains, createClient, WagmiConfig } from "wagmi";

import { bsc, polygon, bscTestnet, polygonMumbai } from "wagmi/chains";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Create a client
const queryClient = new QueryClient();

const chains = [];
process.env.NODE_ENV === "development" ? chains.push(bscTestnet, polygonMumbai, polygon, bsc) : chains.push(polygon, bsc);

// Wagmi client
// const { provider } = configureChains(chains, [
//   walletConnectProvider({ projectId: "b32bdfcc2f890f249bfc334f793f06a3" }),
// ]);
const projectId = "b32bdfcc2f890f249bfc334f793f06a3";
const { provider } = configureChains(chains, [w3mProvider({ projectId })]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

const MyApp: AppType = ({ Component, pageProps }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <WagmiConfig client={wagmiClient}>
        <Component {...pageProps} />
      </WagmiConfig>

      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default MyApp;
